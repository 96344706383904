@use 'sass:color';
@import 'normalize', 'variables';

:root {
  --background-color: #{ $main-dark-color };
  --background-highlight-color: #{ $highlight-dark-color };
  --background-soft-highlight-color: #{ color.scale($main-dark-color, $lightness: 6 * 1.61833%) };
  --main-color: #{ $main-light-color };
  --accent-color: #{ $primary-color };
  --accent-secondary-color: #{ $secondary-color };
  --success-color: #{ $success-color };
  --error-color: #{ $error-color };
}
@media (prefers-color-scheme: light) {
  :root {
    --background-color: #{ $main-light-color };
    --background-soft-highlight-color: #{ color.scale($main-dark-color, $lightness: 100 - 3 * 1.61833%) };
    --main-color: #{ $main-dark-color };
  }
}

html,
body {
  background: var(--background-color);
  font-family: 'Encode Sans', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-size: 18px;
  line-height: #{$spacing-unit}rem;
  margin: 0;
  padding: 0;
}

input,
input::placeholder {
  font-family: 'Encode Sans', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

a {
  color: var(--accent-secondary-color);
  overflow: visible;
  text-decoration: underline;
  transition-duration: 100ms;
  transition-property: text-shadow;
  transition-timing-function: ease-in;

  &:hover {
    background: var(--background-color);
    color: var(--main-color);
    display: inline-block;
    mix-blend-mode: lighten;
    position: relative;

    &::before { /* stylelint-disable-line */
      background: linear-gradient(to right,
      var(--accent-color) 0,
      var(--accent-secondary-color) 100%);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      mix-blend-mode: multiply;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

@media (prefers-color-scheme: light) {
  a:hover {
    mix-blend-mode: darken;
  }

  a:hover::before {
    mix-blend-mode: screen;
  }
}

* {
  box-sizing: border-box;
}

.illustration-main {
  fill: var(--main-color);
}

.illustration-accent {
  fill: var(--accent-secondary-color);
}

.illustration-line {
  fill: var(--background-highlight-color);
}
